export const LOCATION_OPTIONS = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Federated States Of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Anchorage',
  'Montgomery',
  'Fayette',
  'Birmingham',
  'Foley',
  'Huntsville',
  'Tuscaloosa',
  'Star City',
  'Mountain Home',
  'Camden',
  'De Witt',
  'Nashville',
  'Jonesboro',
  'Flippin',
  'Little Rock',
  'Rogers',
  'Tucson',
  'Yuma',
  'Scottsdale',
  'Chandler',
  'Tempe',
  'Lake Havasu City',
  'Maricopa',
  'Prescott Valley',
  'Whittier',
  'Chatsworth',
  'Sacramento',
  'San Francisco',
  'Glendale',
  'Irvine',
  'Gardena',
  'Alameda',
  'La Jolla',
  'Bloomington',
  'Northridge',
  'Newbury Park',
  'Orange',
  'San Bernardino',
  'Adelanto',
  'San Jose',
  'Beverly Hills',
  'Santa Ana',
  'Blythe',
  'Jurupa Valley',
  'Palmdale',
  'Tulare',
  'Palo Alto',
  'Fountain Valley',
  'Walnut Creek',
  'San Diego',
  'Pasadena',
  'Los Angeles',
  'Woodland Hills',
  'Sherman Oaks',
  'Hayward',
  'Costa Mesa',
  'Torrance',
  'Petaluma',
  'Roseville',
  'Anaheim',
  'Fairfield',
  'Greeley',
  'Colorado Springs',
  'Commerce City',
  'Denver',
  'Centennial',
  'Grand Junction',
  'Vail',
  'Boulder',
  'Englewood',
  'Trumbull',
  'Hamden',
  'Norwalk',
  'Meriden',
  'Pomfret Center',
  'Bloomfield',
  'Cheshire',
  'Washington',
  'Lake Worth',
  'Orange Park',
  'Weston',
  'Jacksonville',
  'Melbourne',
  'Havana',
  'Tampa',
  'Daytona Beach',
  'Holly Hill',
  'Sarasota',
  'Deerfield Beach',
  'Miami Beach',
  'Doral',
  'Gainesville',
  'Medley',
  'Orlando',
  'Saint Petersburg',
  'Lakeland',
  'Clearwater',
  'Cape Canaveral',
  'West Palm Beach',
  'Holiday',
  'Pompano Beach',
  'Plant City',
  'Plantation',
  'North Bay Village',
  'Columbus',
  'Milton',
  'Duluth',
  'Atlanta',
  'Thomasville',
  'Lagrange',
  'Macon',
  'Fort Valley',
  'Marietta',
  'Norcross',
  'Newnan',
  'Jasper',
  'La Fayette',
  'Moultrie',
  'Barnesville',
  'Honolulu',
  'Hickam AFB',
  'Ames',
  'Story City',
  'Des Moines',
  'Boone',
  'Wilton',
  'Grinnell',
  'Bettendorf',
  'Centerville',
  'Ida Grove',
  'Orange City',
  'Williamsburg',
  'Idaho Falls',
  'Post Falls',
  'Westmont',
  'Crestwood',
  'Belleville',
  'Chicago',
  'Peoria',
  'Schaumburg',
  'East Dubuque',
  'Champaign',
  'Rantoul',
  'Rockford',
  'Quincy',
  'South Jacksonville',
  'Waukegan',
  'Wood Dale',
  'Broadview',
  'Pekin',
  'East Moline',
  'Riverwoods',
  'Effingham',
  'Burr Ridge',
  'Bolingbrook',
  'Lincoln',
  'Moline',
  'Fort Wayne',
  'Indianapolis',
  'Elkhart',
  'South Bend',
  'Portage',
  'Warsaw',
  'Walkerton',
  'Mishawaka',
  'Muncie',
  'West Lafayette',
  'Plymouth',
  'Berne',
  'Mcpherson',
  'Kansas City',
  'Topeka',
  'Sabetha',
  'Hanson',
  'Louisville',
  'Lexington',
  'Chavies',
  'Liberty',
  'Sulphur',
  'Lafayette',
  'Westport',
  'Burlington',
  'Southbridge',
  'Taunton',
  'Holyoke',
  'New Bedford',
  'Charlton',
  'Pittsfield',
  'Boston',
  'Methuen',
  'Cambridge',
  'Springfield',
  'Lowell',
  'North Brookfield',
  'Newton Upper Falls',
  'Islandia',
  'Sioux Falls',
  'Houma',
  'Green Bay',
  'Fresno',
  'Proctorville',
  'Carlsbad',
  'Cincinnati',
  'Durham',
  'Temecula',
  'Dover',
  'North Chesterfield',
  'Chipley',
  'Pottstown',
  'Albuquerque',
  'Lapeer',
  'Baltimore',
  'Raleigh',
  'Defiance',
  'Flint',
  'San Juan Capistrano',
  'Charlottesville',
  'Tutwiler',
  'Lake Geneva',
  'Mesquite',
  'Phoenix',
  'Twin Lake',
  'Valparaiso',
  'Norton',
  'Chino',
  'Amarillo',
  'Kinston',
  'Batesville',
  'Roosevelt',
  'Bronx',
  'Bokeelia',
  'Long Beach',
  'Bellevue',
  'Huntington',
  'Portland',
  'Glen Carbon',
  'Kahului',
  'Radnor',
  'Sparks',
  'Tualatin',
  'Santa Barbara',
  'Ono',
  'Warren',
  'Westlake',
  'Britton',
  'Homestead',
  'Harrisburg',
  'Oak Lawn',
  'Erie',
  'Pigeon',
  'Du Bois',
  'Santa Monica',
  'Troy',
  'Napa',
  'O Fallon',
  'Ansonville',
  'Vicksburg',
  'Horseheads',
  'Bethesda',
  'Sparta',
  'Grand Rapids',
  'Vestavia Hills',
  'Westville',
  'Homer',
  'Princeton',
  'Keyser',
  'Ridley Park',
  'Rochester',
  'Lancaster',
  'Equality',
  'South Easton',
  'Overland Park',
  'Gonzales',
  'Greenville',
  'Fort Worth',
  'Las Vegas',
  'Youngstown',
  'Auburn',
  'Melville',
  'Williamsport',
  'New Castle',
  'Philadelphia',
  'Fort Lauderdale',
  'Pittsburgh',
  'Baton Rouge',
  'Hammond',
  'De Pere',
  'Osawatomie',
  'Hastings',
  'Richfield',
  'North Las Vegas',
  'Brooklyn',
  'Richmond',
  'Sparks Glencoe',
  'Manchester',
  'Happy Valley',
  'Mount Shasta',
  'Union',
  'Waldorf',
  'Myrtle Beach',
  'Daphne',
  'Summerville',
  'Madison',
  'Reading',
  'Forest City',
  'Duncansville',
  'Buffalo',
  'Seaside Heights',
  'Tulsa',
  'Stevens Point',
  'Concord',
  'Bristol',
  'Halfmoon',
  'Rutland',
  'Ford City',
  'Hampton',
  'Cedar Knolls',
  'Hackensack',
  'Cheyenne',
  'Lakewood',
  'Santa Rosa',
  'Danville',
  'Lady Lake',
  'Chattanooga',
  'Houston',
  'Arlington',
  'Mansfield',
  'Clear Lake',
  'West Hollywood',
  'Billings',
  'Norwell',
  'Niles',
  'Bakersfield',
  'Cleveland',
  'Victorville',
  'Miami Lakes',
  'Holland',
  'Stockton',
  'Dorado',
  'Chantilly',
  'Hempstead',
  'Cold Spring',
  'Fort Bragg',
  'Georgetown',
  'Brookline',
  'Aztec',
  'Roanoke',
  'Rexburg',
  'Coal City',
  'Low Moor',
  'Belleair',
  'Salt Lake City',
  'Cape Neddick',
  'Flowood',
  'Browns Summit',
  'Andrews',
  'Saint Paul',
  'Crawfordville',
  'Waltham',
  'Waukesha',
  'Brookfield',
  'Orchard Park',
  'Waipahu',
  'Downey',
  'Pacific Palisades',
].sort();

export const ISO_CURRENCIES = [
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BOV',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHE',
  'CHF',
  'CHW',
  'CLF',
  'CLP',
  'CNY',
  'COP',
  'COU',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MXV',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'USN',
  'UYI',
  'UYU',
  'UYW',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XBA',
  'XBB',
  'XBC',
  'XBD',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'XSU',
  'XTS',
  'XUA',
  'XXX',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
];

export const STATE_OF_INCORPORATION = [
  'US-AL',
  'US-AK',
  'US-AZ',
  'US-AR',
  'US-CA',
  'US-CO',
  'US-CT',
  'US-DE',
  'US-FL',
  'US-GA',
  'US-HI',
  'US-ID',
  'US-IL',
  'US-IN',
  'US-IA',
  'US-KS',
  'US-KY',
  'US-LA',
  'US-ME',
  'US-MD',
  'US-MA',
  'US-MI',
  'US-MN',
  'US-MS',
  'US-MO',
  'US-MT',
  'US-NE',
  'US-NV',
  'US-NH',
  'US-NJ',
  'US-NM',
  'US-NY',
  'US-NC',
  'US-ND',
  'US-OH',
  'US-OK',
  'US-OR',
  'US-PA',
  'US-RI',
  'US-SC',
  'US-SD',
  'US-TN',
  'US-TX',
  'US-UT',
  'US-VT',
  'US-VA',
  'US-WA',
  'US-WV',
  'US-WI',
  'US-WY',
  'US-DC',
  'US-AS',
  'US-GU',
  'US-MP',
  'US-PR',
  'US-UM',
  'US-VI',
];

export const CRITERIA_COMMA_SEPARATOR = '>|<';

export const LegalEntityType = [
  'Select Legal Entity Type',
  'Corporation',
  'Not for Profit',
  'Limited Liability Company',
  'Government',
  'Individual Proprietorship',
  'Partnership',
];

export const OperatingStatus = ['Open', 'Temporary closed', 'Permanently closed'];

export const BadgeStatus = ['hidden', 'optional', 'mandatory'];

export const ANONYMOUS_ROUTES = ['/external-upload'];
