import { Stack, Text, Button, Box } from '@chakra-ui/react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
const NoResultsProjects = () => {
  const { t } = useTranslation();
  return (
    <Stack p="3" bg="#E9F8ED" h="100%">
      <Text p="5" fontSize="16px">
        {t('There are no recent projects available at the moment.')}
      </Text>
      <Box p="1rem">
        <Button onClick={() => navigate('/projects/create')} w="156px" h="29px" fontSize="13px" colorScheme="green">
          {t('Create a New Project')}
        </Button>
      </Box>
    </Stack>
  );
};
export default NoResultsProjects;
