import { useQuery } from '@apollo/client';
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { navigate } from '@reach/router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import UserQueries from '../../graphql/Queries/UserQueries';
import { convertToShortNo } from '../../utils/number';
import { ProjectCompanyModel } from './evaluation.interface';
const { USER_PROFILE_GQL } = UserQueries;

const ProjectEvaluation = (props: { projectCompany: ProjectCompanyModel; onSelect: () => void }) => {
  const { t } = useTranslation();
  const { projectCompany, onSelect } = props;
  const companyEvaluations = projectCompany.evaluations?.[0] || [];
  const { loading, data: userData } = useQuery(USER_PROFILE_GQL, {
    variables: { externalAuthSystemId: companyEvaluations.createdBy },
    fetchPolicy: 'cache-first',
  });
  const userProfile = userData?.userProfile;
  const numberLabels = {
    thousand: t('K'),
    million: t('mil'),
  };

  const showCompanyBudgetSpend = () => {
    if (companyEvaluations && companyEvaluations.budgetSpend && companyEvaluations.budgetSpend > 0) {
      return (
        <Text marginTop="4px" as="h6" textStyle="h6" color="#000">
          {`${t('Spend')}: ${convertToShortNo(numberLabels, companyEvaluations.budgetSpend)}`}
        </Text>
      );
    }
  };

  const startEvaluation = !companyEvaluations || !companyEvaluations.submitted;
  return (
    <Flex width="auto" flexDirection="column" marginTop="2.5rem">
      <Text
        marginBottom=".4rem"
        fontSize="18px"
        onClick={() => navigate(`/company/${projectCompany?.companyId}`)}
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}
      >
        {projectCompany.company.legalBusinessName}
      </Text>
      <Flex flexDirection="row">
        {showCompanyBudgetSpend()}
        {!startEvaluation && (
          <>
            <span style={{ margin: '0 0.3rem 0rem 0.3rem' }}>&#8729;</span>
            <Text marginTop="4px" as="h6" textStyle="h6" color="#000">
              {t('Evaluation Completed')}
            </Text>
            <span style={{ margin: '0 0.3rem 0rem 0.3rem' }}>&#8729;</span>
            <Flex flexDirection="row" marginTop="4px" fontSize="12px">
              {moment(new Date(companyEvaluations.created)).format('MM/DD/YYYY')}
              {t(', by ')}
              {loading && userProfile?.givenName && userProfile?.surname ? (
                <Spinner />
              ) : (
                <Text textDecoration="underline" marginLeft="3px" color="green">
                  {`${userProfile?.givenName} ${userProfile?.surname}`}
                </Text>
              )}
            </Flex>
          </>
        )}
      </Flex>
      {startEvaluation ? (
        <Box>
          <Text fontWeight="400" fontSize="12px" color="#2A2A28" mb="10px">
            Get started on your evaluation of this company.
          </Text>
          <Button colorScheme="green" variant="outline" size="sm" onClick={onSelect}>
            {t('Start')}
          </Button>
        </Box>
      ) : (
        <Box marginTop=".7rem">
          <Button
            id="project-evaluation-view-button-000"
            variant="outline"
            colorScheme="green"
            size="sm"
            onClick={onSelect}
          >
            {t('View')}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default ProjectEvaluation;
