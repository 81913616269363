import { makeStyles } from '@material-ui/core';

export default makeStyles({
  container: {
    height: '100vh',
  },
  paper: {
    padding: 25,
  },
});
