import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Stack, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { CustomTooltip } from '../../GenericComponents/CustomTooltip';
import { styleNumber, styleNumberOfResults } from '../commonStyles';
import { Company } from '../company.types';
import { CompanyProfileDivider } from '../shared';
import DisplayView from './DisplayView';
import InsuranceFilters from './InsuranceFilters';
import UpdateView from './UpdateView';

const InsurancePanel = forwardRef((props: { company: Company; edit: boolean }, ref) => {
  const { company, edit } = props;
  const insurances = useMemo(() => company.insuranceCoverage?.results ?? [], [company]);
  const { t } = useTranslation();
  const [isGridView, setIsGridView] = useState<boolean>(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [count, setcount] = useState(0);

  useEffect(() => {
    if (company.insuranceCoverage?.results) {
      setcount(company.insuranceCoverage?.results.length);
    }
  }, [company]);

  const filteredInsurances = useMemo(
    () => insurances.filter((insurance) => selectedTypes.includes(insurance.type)),
    [insurances, selectedTypes]
  );

  const availableTypes = useMemo(
    () => Array.from(new Set((insurances || []).map((insurance) => insurance.type))),
    [insurances]
  );

  return (
    <Stack spacing={0} id="insurance">
      <Stack direction="column" spacing={4}>
        <Stack direction="row">
          <Flex>
            <Text as="h1" textStyle="h1_profile" marginRight="1.5">
              {t('Insurance')}
            </Text>
            <CustomTooltip
              label={
                'Insurances that are available for the company including workers compensation, property, casualty, tax insurance, etc.'
              }
            >
              <Box lineHeight="2rem">
                <InfoOutlineIcon color="gray" />
              </Box>
            </CustomTooltip>
            {count > 0 && (
              <Flex sx={styleNumberOfResults} marginTop="-2.5%">
                <Text sx={styleNumber}>{count}</Text>
              </Flex>
            )}
          </Flex>
          <Flex flexDirection="row" position="absolute" right="3rem">
            <InsuranceFilters
              isOpen={isFiltersOpen}
              setIsOpen={setIsFiltersOpen}
              count={selectedTypes.length ? filteredInsurances.length : undefined}
              filterHook={[selectedTypes, setSelectedTypes]}
              availableTypes={availableTypes}
            />
            <Button
              {...(isGridView && { bg: 'gradient.iconbutton' })}
              {...(isFiltersOpen && { opacity: '.5' })}
              onClick={() => setIsGridView(true)}
              size="sm"
              colorScheme="gradient.iconbutton"
              variant="ghost"
              borderRadius="28px"
            >
              <Text textStyle="textLink">{t('Grid View')}</Text>
              {isGridView && (
                <Icon
                  fontSize="12px"
                  margin="7px 0 7px 7px"
                  as={IoMdClose}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsGridView(false);
                  }}
                />
              )}
            </Button>
          </Flex>
        </Stack>
        <CompanyProfileDivider />
      </Stack>
      {edit ? (
        <UpdateView
          ref={ref}
          companyId={company.id}
          insurances={selectedTypes.length ? filteredInsurances : insurances}
        />
      ) : (
        <DisplayView isGridView={isGridView} insurances={selectedTypes.length ? filteredInsurances : insurances} />
      )}
    </Stack>
  );
});

export default InsurancePanel;
