import { Box, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { totalNumberSidebar } from '../../utils/number';

const CustomMenuItem = (props: { active: boolean; title: string; onClick?: any; leftIcon?: any; total?: any }) => {
  const { active, title, onClick, leftIcon, total } = props;
  const { t } = useTranslation();
  return (
    <Box
      {...(active && { bg: 'white', color: 'green.600' })}
      {...(!active && { _hover: { bg: 'rgba(255, 255, 255, .65)' } })}
      p={`.2rem ${leftIcon ? '0' : '1'}rem`}
      m={`.1rem 0`}
      cursor="pointer"
      borderRadius="20"
      onClick={onClick}
      display="flex"
    >
      {leftIcon}
      <HStack>
        <Text textStyle="sidenavParent">{t(title)}</Text>
        <Text fontSize="small" color="GrayText">
          {totalNumberSidebar(total as number)}
        </Text>
      </HStack>
    </Box>
  );
};

export default CustomMenuItem;
