import { Button } from '@chakra-ui/button';
import { Box, Flex, Stack, Text } from '@chakra-ui/layout';
import { Popover, PopoverBody, PopoverContent, PopoverFooter, PopoverTrigger } from '@chakra-ui/popover';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventFilter } from './ActivityLog';
import { getLastWeekDate } from '../../../utils/date';
import DateField from './DateField';

const LogFilters: FC<{ applyFilters: (data: EventFilter) => void; count: number }> = (props) => {
  const { applyFilters, count } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [projectFilter, setProjectFilter] = useState<string | null>(null);

  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(new Date());

  const resetFilters = () => {
    setProjectFilter(null);
    setCompanyId(null);
    applyFilters({
      timestampFrom: startDate,
      timestampTo: endDate,
    });
  };

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <Button
          onClick={() => setIsOpen(true)}
          variant={isOpen ? 'rounded' : 'simple'}
          _hover={{
            bg: 'linear-gradient(179.97deg, rgba(176, 226, 187, 0.375) 0.03%, rgba(146, 214, 193, 0.375) 99.97%), #FFFFFF',
          }}
          borderRadius="28px"
          maxH="34px"
        >
          <Text textStyle="textLink">{t('Filters')}</Text>
        </Button>
      </PopoverTrigger>
      <Box zIndex="100">
        <PopoverContent
          bg="#FCFCFC"
          mr="5"
          width="auto"
          borderRadius="0"
          border="1px solid #E4E4E4"
          borderColor="#E4E4E4"
          boxShadow="0px 1px 2px rgba(0, 0, 0, 0.25) !important"
        >
          <PopoverBody bg="#F8F8F8" p="1.5rem" w="320px">
            <Stack alignItems="flex-start">
              <Stack spacing={1}>
                <Text as="h4" textStyle="h4">
                  {t('Date')}
                </Text>
                <Stack direction="row" spacing={2}>
                  <Stack spacing={0}>
                    <Text textStyle="body">{t('Start')}</Text>
                    <DateField setDate={setStartDate} date={startDate} />
                  </Stack>
                  <Stack spacing={0}>
                    <Text textStyle="body">{t('End')}</Text>
                    <DateField setDate={setEndDate} date={endDate} />
                  </Stack>
                </Stack>
              </Stack>
              <Button p="0" onClick={resetFilters} variant="simple">
                <Text textStyle="textLink" color="green.600">
                  {t('Reset Filters')}
                </Text>
              </Button>
            </Stack>
          </PopoverBody>
          <PopoverFooter bg="green.600" h="47px">
            <Flex flex="1" direction="row-reverse" alignItems="flex-start">
              <Button
                onClick={() => {
                  applyFilters({
                    timestampFrom: startDate,
                    timestampTo: endDate,
                    ...(!!companyId && { companyId }),
                    ...(!!projectFilter && { projectId: projectFilter as any }),
                  });
                  setIsOpen(false);
                }}
                colorScheme="green"
                variant="outline"
                bg="white"
                size="sm"
              >
                <Text textStyle="squareSolidActive">
                  {`${t('Apply filters')} (${[startDate, endDate, companyId, projectFilter].filter((i) => i).length})`}
                </Text>
              </Button>
              {count && (
                <Text color="white" fontWeight="600" marginRight="18px" marginTop="6px" fontSize="12px">
                  {count + ' Results'}
                </Text>
              )}
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </Box>
    </Popover>
  );
};

export default LogFilters;
