import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
const NoResultsMyActivity = () => {
  const { t } = useTranslation();

  return (
    <Stack p="3" bg="#E9F8ED" h="100%">
      <Text p="5" fontSize="14px">
        {t('Start interacting with the Stimulus platform and the latest activities will be shown here.')}
      </Text>
      <Box p="1rem">
        <Button
          onClick={() => navigate('/companies/all/list/1')}
          w="156px"
          h="29px"
          fontSize="13px"
          colorScheme="green"
        >
          {t('View Companies')}
        </Button>
      </Box>
    </Stack>
  );
};
export default NoResultsMyActivity;
